<template>
  <div class="clearfix">
    <vs-row>
      <!-- 身分證或手機 tabs -->
      <vs-col>
        <!-- <vs-tabs alignment="fixed" v-model="identify_radio">
          身分證
          <vs-tab :label="$t('login.id')" @click="switchtab(0)">
          </vs-tab>
          手機
          <vs-tab :label="$t('login.phone')" @click="switchtab(1)">
          </vs-tab>
        </vs-tabs> -->
        <div class="flex mb-2">
          <div class="p-2 w-1/2 text-center cursor-pointer" :style="identify_radio == 0 ? 'border-bottom: 2px solid #003f8e; color: #003f8e;' : ''" @click="switchtab(0)">
            <span>{{ $t('login.id') }}</span>
          </div>
          <div class="p-2 w-1/2 text-center cursor-pointer" :style="identify_radio == 1 ? 'border-bottom: 2px solid #003f8e; color: #003f8e;' : ''" @click="switchtab(1)">
            <span>{{ $t('login.phone') }}</span>
          </div>
        </div>
      </vs-col>
      <!-- 姓名 -->
      <vs-col class="cd-form-group" vs-type="flex" vs-justify="space-between">
        <!-- 姓 -->
        <vs-input
          class="w-full mr-1"
          v-validate="'required'"
          data-vv-validate-on="blur"
          :data-vv-as="$t('login.firstName')"
          name="displayFirstName"
          :placeholder="$t('login.firstName')"
          v-model="displayFirstName"
          :warning="errorBags.first('displayFirstName')"
          :warning-text="errorBags.first('displayFirstName')"
          val-icon-warning="warning" />
        <!-- 名 -->
        <vs-input
          class="w-full ml-1"
          v-validate="'required'"
          data-vv-validate-on="blur"
          :data-vv-as="$t('login.lastName')"
          name="displayLastName"
          :placeholder="$t('login.lastName')"
          v-model="displayLastName"
          :warning="errorBags.first('displayLastName')"
          :warning-text="errorBags.first('displayLastName')"
          val-icon-warning="warning" />
      </vs-col>
      <!-- 註冊身分 -->
      <vs-col class="cd-form-group">
        <v-select
          class="w-full cd-select"
          autocomplete
          :clearable="false"
          v-model="doctortype"
          :options="doctoroptions"
          label="text"
          :reduce="doctoroptions => `${doctoroptions.value}`" />
      </vs-col>
      <!-- 職稱 -->
      <vs-col class="cd-form-group">
        <v-select
          class="w-full cd-select"
          :placeholder="$t('login.selectTitle')"
          autocomplete
          :clearable="false"
          v-model="selectedTitle"
          :options="medicalTitleList"
          label="name" />
      </vs-col>
      <!-- 身分證字號 -->
      <vs-col class="cd-form-group" v-if="identify_radio==0">
        <vs-input
          class="w-full"
          v-validate="'required|min:10|max:10'"
          data-vv-validate-on="blur"
          :data-vv-as="$t('login.id')"
          name="account"
          icon-no-border
          icon-pack="feather"
          icon="icon-user"
          :placeholder="$t('login.id')"
          :warning="errorBags.first('account')"
          :warning-text="errorBags.first('account')"
          val-icon-warning="warning"
          v-model="account" />
      </vs-col>
      <!-- 手機國碼 -->
      <vs-col class="cd-form-group">
        <v-select
          class="w-full cd-select"
          autocomplete
          :clearable="false"
          v-model="countryCode"
          :options="userinfo_country_code"
          label="label"
          :reduce="userinfo_country_code => `${userinfo_country_code.phoneCode}`" />
      </vs-col>
      <!-- 手機號碼 -->
      <vs-col class="cd-form-group">
        <vs-input
          class="w-full"
          v-validate="'required|min:9'"
          data-vv-validate-on="blur"
          :data-vv-as="$t('login.mobile')"
          name="mobile"
          icon-no-border
          icon-pack="feather"
          icon="icon-phone"
          :placeholder="$t('login.mobile')"
          :warning="errorBags.first('mobile')"
          :warning-text="errorBags.first('mobile')"
          val-icon-warning="warning"
          v-model="mobile" />
      </vs-col>
      <!-- 密碼 -->
      <vs-col class="cd-form-group" vs-type="flex">
        <vs-input
          class="w-full"
          v-validate="'required|min:6|max:10'"
          data-vv-validate-on="blur"
          :data-vv-as="$t('login.password')"
          name="password"
          ref="password"
          :type="passwordType"
          icon-no-border
          icon-pack="feather"
          icon="icon-lock"
          :placeholder="$t('login.registerpw')"
          :warning="errorBags.first('password')"
          :warning-text="errorBags.first('password')"
          val-icon-warning="warning"
          v-model="password">
        </vs-input>
        <feather-icon class="cd-icon-muted ml-2 mt-3" :icon="passwordIcon" @click="showPassword" />
      </vs-col>
      <!-- 再次輸入密碼 -->
      <vs-col class="cd-form-group" vs-type="flex">
        <vs-input
          class="w-full"
          v-validate="'required|min:6|max:10|confirmed:password'"
          data-vv-validate-on="blur"
          :data-vv-as="$t('login.passwordConfirm')"
          name="confirm_password"
          :type="confirm_passwordType"
          icon-no-border
          icon-pack="feather"
          icon="icon-lock"
          :placeholder="$t('login.passwordConfirm')"
          :warning="errorBags.first('confirm_password')"
          :warning-text="errorBags.first('confirm_password')"
          val-icon-warning="warning"
          v-model="confirm_password">
        </vs-input>
        <feather-icon class="cd-icon-muted ml-2 mt-3" :icon="confirm_passwordIcon" @click="confirm_showPassword" />
      </vs-col>
      <!-- 手機驗證 -->
      <vs-col class="cd-form-group" vs-type="flex" vs-justify="space-between" vs-align="flex-start">
        <!-- 手機驗證碼 -->
        <vs-input
          class="w-2/3"
          v-validate="'required|digits:4|max:4'"
          data-vv-validate-on="blur"
          :data-vv-as="$t('login.mobileVerifyCode')"
          name="vcode"
          icon-no-border
          icon-pack="feather"
          icon="icon-shield"
          :placeholder="$t('login.mobileVerifyCode')"
          :warning="errorBags.first('vcode')"
          :warning-text="errorBags.first('vcode')"
          val-icon-warning="warning"
          v-model="vcode" />
        <!-- 寄發驗證碼按鈕 -->
        <vs-button class="sm:px-4 px-2 py-3 cd-button" @click="getVCode" :disabled="vBtnDisable">
          {{vBtnText}}
          <countdown v-if="counting" :time="3 * 60 * 1000" @end="handleCountdownEnd">
            <template slot-scope="props">{{ $t('login.timeLeft') }}{{props.minutes}}{{"分"}}{{ props.seconds }}{{ $t('login.seconds') }}</template>
          </countdown>
        </vs-button>
      </vs-col>
      <!-- 我同意註冊合約 -->
      <vs-col class="cd-form-group" vs-type="flex" vs-align="center">
        <vs-checkbox v-model="isTermsConditionAccepted">{{$t('login.agree')}}</vs-checkbox>
        <p class="cd-p-pointer text-primary" @click="copyrightclickbutton">{{$t('login.privacy')}}</p>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="space-between">
        <!-- 返回登入按鈕 -->
        <vs-button class="px-3 sm:px-4" type="border" to="/pages/login">{{$t('login.back')}}</vs-button>
        <!-- 註冊按鈕 -->
        <vs-button class="px-6 sm:px-8" @click="registerUserJWt" :disabled="!validateForm||errorBags.any()">{{$t('login.register')}}</vs-button>
      </vs-col>
    </vs-row>
    <!-- 註冊合約 -->
    <vs-popup :title="$t('login.privacy')" :active.sync="popupCopyRightCommon">
      <copy-right></copy-right>
    </vs-popup>
  </div>
</template>

<script>
import {countryCodes} from '@/api/countryCodes.js'
import axios from 'axios'
import vSelect from 'vue-select'
import copyRight from '../../views/apps/emrcomponents/Copyright'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  data() {
    return {
      counting: false,
      mobileInputDisable: false,
      vBtnDisable: true,
      vBtnText: this.$t('login.mobileVerify'),
      identify_radio:0,
      vcode: '',
      countryCode: '+886',
      phone: '',
      displayFirstName: '',
      displayLastName: '',
      mobile: '',
      account: '',
      doctortype: this.$t('login.selectType'),
      password: '',
      switchpassword:false,
      confirm_password: '',
      isTermsConditionAccepted: false,
      doctoroptions:[
        {text:'醫師',value:0},
        {text:'護理師',value:2},
        {text:'藥師',value:3},
      ],
      passwordType: 'password',
      passwordIcon: 'EyeOffIcon',
      confirm_passwordType: 'password',
      confirm_passwordIcon: 'EyeOffIcon',
      popupCopyRightCommon: false,
      selectedTitle: null,
    }
  },
  watch: {
    // 檢查手機號碼 來開關 寄發驗證碼按鈕
    mobile(n) {
      if (this.counting == false){
        if (n.length == 10||n.length==9){
          this.vBtnDisable = false
        }else{
          this.vBtnDisable = true
        }
      }
    }
  },
  components: {
    copyRight,
    vSelect,
  },
  computed: {
    validateForm() {
      return this.displayFirstName != '' && this.displayLastName != '' && this.password != '' && this.confirm_password != '' && this.isTermsConditionAccepted === true;
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    userinfo_country_code() {
      countryCodes.forEach(element => {
        if(this.$i18n.locale=='tw'){
          element.label = element.countryName +' ('+element.phoneCode+')'
        }else if(this.$i18n.locale=='cn'){
          element.label = element.countryNameCN +' ('+element.phoneCode+')'
        }else if(this.$i18n.locale=='en'){
          element.label = element.countryCode +' ('+element.phoneCode+')'
        }
      });
      return countryCodes
    },
    medicalTitleList() {
      var _self = this
      var medicalTitleList = []
      if(_self.$store.getters.medicalTitleList){
        _self.$store.getters.medicalTitleList.forEach(function(v){
          _self.$i18n.locale == 'en' ?
          medicalTitleList.push({ "id": v.id, "name": v.ename }):
          medicalTitleList.push({ "id": v.id, "name": v.cname });
        })
      }
      return medicalTitleList
    },
  },
  mounted() {

    this.partner=localStorage.getItem('register_partner', this.partner)
    if(!this.partner){
      this.partner=4143
    }
    if(this.$i18n.locale=='en'){
      this.$validator.localize('en');
    }else{
      this.$validator.localize('zhTW');
    }
    // 取職稱list
    this.$store.dispatch('fetchMedicalTitle')
    // 取角色list
    axios.get(process.env.VUE_APP_API_URL_HIS+'/api/doctortype').then(res => {
      if(res.data.status=='OK'){
        this.doctoroptions = Object.keys(res.data.data).map((key) => {
          if(this.$i18n.locale=='en'){
            return {
              'text': res.data.data[key].ename,
              'value': res.data.data[key].mc_id
            }
          }else{
            return {
              'text': res.data.data[key].cname,
              'value': res.data.data[key].mc_id
            }
          }
        })
      }
    })
  },
  methods: {
    // 寄發驗證碼 開始倒數
    startCountdown: function () {
      this.counting = true;
    },
    // 寄發驗證碼 倒數結束
    handleCountdownEnd: function () {
      this.counting = false
      this.vBtnText = this.$t('login.mobileVerify')
      if (this.mobile.length == 10||this.mobile.length==9){
        this.vBtnDisable = false
      }else{
        this.vBtnDisable = true
      }
    },
    // 寄發手機驗證碼
    getVCode() {
      this.mobileInputDisable = true
      this.vBtnDisable = true
      this.startCountdown()
      this.vBtnText = ''
      if(this.countryCode =="+886" &&this.mobile.charAt(0)!="0"){
        this.mobile = "0"+this.mobile
      }
      axios.post(process.env.VUE_APP_API_URL_HIS+'/api/getmobileverifycode', {countrycode: this.countryCode, mobile: this.mobile, lang: this.$i18n.locale}).then((res) => {
        if(res.data.status=='OK'){
          this.$vs.notify({
            color: 'gray',
            text: this.$t('login.deliverSuccessful'),
            position:'top-center'
          })
        }else{
          this.$vs.notify({
            color: 'danger',
            text: this.$t('login.deliverFailed'),
            position:'top-center'
          })
        }
      })
    },
    // 註冊
    registerUserJWt() {
      var _self = this
      let account_type = 0
      // If form is not validated or user is already login return
      if (!_self.validateForm) return
      // 判斷是否為電話
      if(_self.identify_radio==1){
        _self.account = _self.countryCode.split('+')[1]+_self.mobile
        account_type = 2 // 手機註冊
      }
      const payload = {
        firstname: _self.displayFirstName,//姓
        lastname: _self.displayLastName,//名
        countryCode: _self.countryCode,
        mobile: _self.mobile,
        account: _self.account,
        applytype: _self.doctortype,
        medical_title_id: _self.selectedTitle.id,
        vcode: _self.vcode,
        password: _self.password,
        confirmPassword: _self.confirm_password,
        account_type: account_type,
        partnerid:_self.partner
      }
      _self.$store.dispatch('registerUser', payload).then(res => {
        if(res.data.status=='OK'){
          _self.$router.push('/pages/login')
          _self.$vs.notify({
            color: 'grey',
            text: res.data.message,
            position:'top-center'
          })
        }else if(res.data.status=='ERROR'){
          _self.$vs.notify({
            color: 'danger',
            text: res.data.message,
            position:'top-center'
          })
        }
      })
    },
    // 手機國碼語系
    countryCodeTranslation(item){
      if(this.$i18n.locale=='tw'){
        return item.countryName +' ('+item.phoneCode+')'
      }else if(this.$i18n.locale=='cn'){
        return item.countryNameCN +' ('+item.phoneCode+')'
      }else if(this.$i18n.locale=='en'){
        return item.countryCode +' ('+item.phoneCode+')'
      }
    },
    // 顯示密碼
    showPassword(){
      this.passwordType == "text" ? this.passwordType = "password" : this.passwordType = "text"
      this.passwordType == "text" ? this.passwordIcon = "EyeIcon" : this.passwordIcon = "EyeOffIcon"
    },
    // 顯示確認密碼
    confirm_showPassword(){
      this.confirm_passwordType == "text" ? this.confirm_passwordType = "password" : this.confirm_passwordType = "text"
      this.confirm_passwordType == "text" ? this.confirm_passwordIcon = "EyeIcon" : this.confirm_passwordIcon = "EyeOffIcon"
    },
    // 切換 身分證/手機 tab
    switchtab(index){
      this.identify_radio = index
    },
    copyrightclickbutton(){
      this.popupCopyRightCommon = true
    },
  }
}
</script>
